<template>
  <q-page :class="['row', $style.page]">
    <div class="col">
      <div class="row">
        <div :class="[' q-py-md col-md-7  q-mr-xl', $style.intro]">
          <div class="col">
            <h4 :class="$style.title">Oliver Newman</h4>
            <p :class="$style.date">16th November 1990 - 11th July 2019</p>
            <p :class="$style.description">
              A talented, passionate and kind man. Oliver was well loved by
              family, friends and colleagues. He loved to make music and would
              probably be shouting at me for putting these unfinished songs
              online, but it is a little piece of him that everyone can enjoy.
              He was humble with his talents and worked hard to achieve anything
              he set his mind to. This followed through into his career and
              education. Oliver was positive, thoughtful and would make people
              feel at ease.
            </p>
          </div>
        </div>
        <div
          :class="[
            'col-4-md self-center ',
            isMobile ? '' : 'q-ml-xl q-pl-xl',
            $style.rosemary,
          ]"
        >
          <q-img
            fit="contain"
            height="160px"
            width="160px"
            src="../assets/images/rosemary.svg"
          />
        </div>
      </div>
      <div class="row q-pr-md">
        <div :class="['col q-pr-md', $style.imgSection1]" v-show="!isMobile">
          <q-carousel
            height="440px"
            animated
            transition-next="fade"
            transition-duration="1000"
            :autoplay="6000"
            v-model="slide"
            infinite
          >
            <q-carousel-slide :name="1" img-src="../assets/images/oli.jpeg" />
            <q-carousel-slide :name="2" img-src="../assets/images/logan.jpg" />
            <q-carousel-slide :name="3" img-src="../assets/images/rugby.jpg" />
          </q-carousel>
        </div>
        <div :class="['col', $style.img]" v-show="!isMobile">
          <div class="col-6 q-pb-sm">
            <q-carousel
              height="210px"
              animated
              transition-next="fade"
              :autoplay="10000"
              transition-duration="1000"
              v-model="slide1"
              infinite
            >
              <q-carousel-slide :name="1" img-src="../assets/images/cat.jpg" />
              <q-carousel-slide :name="2" img-src="../assets/images/jazz.jpg" />
              <q-carousel-slide
                :name="3"
                img-src="../assets/images/crete.jpg"
              />
            </q-carousel>
          </div>
          <div class="col-6 q-pt-sm">
            <q-carousel
              height="210px"
              animated
              transition-next="fade"
              transition-duration="1000"
              :autoplay="8000"
              v-model="slide2"
              infinite
            >
              <q-carousel-slide :name="1" img-src="../assets/images/hat.jpg" />
              <q-carousel-slide
                :name="8"
                img-src="../assets/images/candyfloss.jpg"
              />
              <q-carousel-slide :name="2" img-src="../assets/images/bus.jpg" />

              <q-carousel-slide :name="3" img-src="../assets/images/bike.jpg" />

              <q-carousel-slide :name="4" img-src="../assets/images/ski.jpg" />
            </q-carousel>
          </div>
        </div>
        <div
          :class="['col-12', isMobile ? '' : 'q-pr-md', $style.imgSection1]"
          v-show="isMobile"
        >
          <q-carousel
            height="360px"
            animated
            autoplay
            transition-next="fade"
            transition-duration="1000"
            v-model="slide3"
            infinite
          >
            <q-carousel-slide :name="1" img-src="../assets/images/oli.jpeg" />
            <q-carousel-slide :name="2" img-src="../assets/images/logan.jpg" />
            <q-carousel-slide :name="3" img-src="../assets/images/rugby.jpg" />
            <q-carousel-slide :name="4" img-src="../assets/images/cat.jpg" />
            <q-carousel-slide :name="5" img-src="../assets/images/jazz.jpg" />
            <q-carousel-slide :name="6" img-src="../assets/images/crete.jpg" />
            <q-carousel-slide :name="7" img-src="../assets/images/hat.jpg" />
            <q-carousel-slide
              :name="8"
              img-src="../assets/images/candyfloss.jpg"
            />
            <q-carousel-slide :name="9" img-src="../assets/images/bus.jpg" />

            <q-carousel-slide :name="10" img-src="../assets/images/bike.jpg" />

            <q-carousel-slide :name="11" img-src="../assets/images/ski.jpg" />
          </q-carousel>
        </div>

        <div :class="[isMobile ? 'col-12' : 'col q-px-xl']">
          <AudioPlayer />
          <VideoPlayer />
        </div>
      </div>
      <div class="row items-center no-wrap justify-left q-py-md">
        <div class="col-shrink">
          <q-img src="../assets/images/quote.svg" height="50px" width="50px" />
        </div>
        <div :class="['q-pl-lg', isMobile ? 'col-10' : 'col-11']">
          <p :class="$style.pageQuote">
            A great oak has fallen, but the forest remains strong.
          </p>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { ref } from "vue";
import AudioPlayer from "./AudioPlayer.vue";
import VideoPlayer from "./VideoPlayer.vue";

export default {
  name: "OliPage",
  components: {
    AudioPlayer,
    VideoPlayer,
  },
  setup() {
    return {
      slide: ref(1),
      slide1: ref(1),
      slide2: ref(1),
      slide3: ref(1),
    };
  },
  computed: {
    isMobile() {
      return this.$q.screen.lt.md;
    },
  },
};
</script>

<style lang="scss" module>
.page {
  margin: auto;
}
.title {
  font-family: "Merriweather", Helvetica, Arial;
  font-size: 36px;
  color: #3c5c54;
  font-weight: 700px;
  margin: 0px;
}

.date {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.8;
  padding-top: 5px;
}
.description {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  opacity: 0.8;
}
.imgSection1 {
  width: 454px;
  @media only screen and (max-width: 1024px) {
    width: 360px;
  }
}

.img {
  width: 335px;
}

.intro {
  width: 809px;
  @media only screen and (max-width: 1024px) {
    order: 2;
  }
}
.rosemary {
  @media only screen and (max-width: 1024px) {
    order: 1;
  }
}
.pageQuote {
  font-family: Merriweather;
  font-style: italic;
  font-size: 21px;
  letter-spacing: 0.4px;
  line-height: 36px;
  color: #3c5c54;
  margin: 0px;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.quote {
  height: 100px;
}
</style>
