<template>
  <div :class="[' q-pt-md']">
    <VuePlyr ref="plyr" :options="options">
      <video
        controls
        crossorigin
        playsinline
        :class="$style.videoPlayer"
        poster="../assets/images/piano.jpg"
      >
        <source src="../assets/video/piano.mp4" type="video/mp4" />
      </video>
    </VuePlyr>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  data() {
    return {
      options: {
        controls: ["play-large", "mute", "fullscreen"],
      },
    };
  },
  mounted() {
    this.player = this.$refs.plyr;
  },
  computed: {
    isMobile() {
      return this.$q.screen.lt.md;
    },
  },
  methods: {},
};
</script>
<style lang="scss" module>
.videoPlayer {
  width: 100%;
  height: 198px;

  @media only screen and (max-width: 1024px) {
    height: 300px;
  }
}
</style>
