<template>
  <q-layout view="lHh Lpr lFf" :class="$style.page">
    <q-page-container>
      <OliPage />
    </q-page-container>
  </q-layout>
</template>

<script>
import OliPage from "./components/OliPage.vue";

export default {
  name: "App",

  components: {
    OliPage,
  },
};
</script>

<style lang="scss" module>
@font-face {
  font-family: "Merriweather";
  src: local("Merriweather"),
    url(./assets/fonts/Merriweather-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Mulish";
  src: local("Mulish"),
    url(./assets/fonts/Mulish-Regular.ttf) format("truetype");
}

body {
  background-color: #e5e3dd;
  margin: 50px;
}

.page {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 1%;
  padding-bottom: 5%;
  @media only screen and (max-width: 900px) {
    padding-left: 5%;
    padding-right: 5%;
  }
}
</style>
