<template>
  <div :class="['text-center', isMobile ? 'q-mt-lg' : '']">
    <p>
      <strong>Click play to listen to some of Oliver's samples.</strong>
    </p>
    <VuePlyr :key="this.selected" ref="plyr">
      <audio controls crossorigin playsinline>
        <source :src="this.selected" type="audio/mp3" />
      </audio>
    </VuePlyr>
  </div>
  <q-list>
    <q-item
      v-ripple
      clickable
      @click="setLodestar"
      :class="['row justify-between  q-px-sm ', $style.trackPanel]"
    >
      <q-item-section class="col-9 q-mb-none">
        <div :class="$style.name">Lodestar</div>
        <div :class="$style.track">Track 1</div>
      </q-item-section>
      <q-item-section>
        <div :class="[$style.track, 'col-2 self-end q-mt-sm']">3:43</div>
      </q-item-section>
    </q-item>
    <q-item
      clickable
      v-ripple
      @click="setMori"
      :class="['row justify-between  q-px-sm ', $style.trackPanel]"
    >
      <q-item-section class="col-9 q-mb-none">
        <div :class="$style.name">Mori</div>
        <div :class="$style.track">Track 2</div>
      </q-item-section>
      <q-item-section>
        <div :class="[$style.track, 'col-2 self-end q-mt-sm']">1:57</div>
      </q-item-section>
    </q-item>
    <q-item
      clickable
      v-ripple
      @click="setSkin"
      :class="['row justify-between q-px-sm ', $style.trackPanel]"
    >
      <q-item-section class="col-9 q-mb-none">
        <div :class="$style.name">You've got me under your skin</div>
        <div :class="$style.track">Track 3</div>
      </q-item-section>
      <q-item-section>
        <div :class="[$style.track, 'col-2 self-end q-mt-sm']">2:04</div>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
export default {
  name: "AudioPlayer",
  data() {
    return {
      lodestar:
        "https://ewheld.imgix.net/uploads/a40b2d9b56fe734ff66714dcb60d34418699304e.mp3",
      mori: "https://ewheld.imgix.net/uploads/f367e1b41377ce1d43bddcc623b699ffe848a2b3.mp3",
      skin: "https://ewheld.imgix.net/uploads/2bbde5382ac2bf3c57f93ec2ad4c73e49208ef4a.mp3",
      selected: "",
      player: "",
    };
  },
  mounted() {
    this.selected = this.lodestar;

    this.player = this.$refs.plyr;
  },
  computed: {
    isMobile() {
      return this.$q.screen.lt.md;
    },
  },
  methods: {
    setLodestar() {
      this.selected = this.lodestar;
      this.player.source = this.selected;
    },
    setMori() {
      this.selected = this.mori;
      this.player.source = this.selected;
    },
    setSkin() {
      this.selected = this.skin;
      this.player.source = this.selected;
    },
  },
};
</script>
<style lang="scss" module>
.trackPanel {
  border-bottom: 0.2px #3e3e3f solid;
}

.track {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3e3e3f;

  opacity: 0.5;
}

.name {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;

  color: #3b5d54;
}
</style>
